<template>
  
  <div>
    <!-- <template>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template> -->

    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label> <router-link :to="{ name: listPath[ebooks.item_type] }"
          class="text-capitalize  text-gray-900 font-semibold">
          {{ ebooks.item_type }}</router-link> <i
          class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> {{ ebooks.name }}</span>
      </label>
      <h4 class="text-xl capitalize"> {{ ebooks.name }}</h4>
    </div>

    <div class="bg-white w-full rounded-lg mt-3 mb-3 px-4 py-4">
      <div class="flex justify-between items-end">
        <div>
          <h4 class="text-xl font-bold capitalize"> {{ ebooks.item_type }} Details</h4>
        </div>
        <div class="pt-4">
          <button @click="goToEdit" class="bg-blue-500 p-2 px-2 text-white rounded-lg flex justify-start items-center">
            <svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                clip-rule="evenodd" />
              <path fill-rule="evenodd"
                d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                clip-rule="evenodd" />
            </svg>
            Edit Details
          </button>
        </div>
      </div>
      <div class="flex justify-start mt-2">
        <div class="w-1/2 flex-col">
          <label class="font-semibold text-sm mb-0">Name</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ebooks.name }}
          </p>
          <label class="font-semibold text-sm mb-0">Edition Code</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ebooks.edition_code }}
          </p>
          <label class="font-semibold text-sm mb-0">Vendor</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ebooks.brands ? ebooks.brands.vendor.name : '' }}
          </p>
          <label class="font-semibold text-sm mb-0">Authors</label>
          <p class="text-base text-gray-500 font-normal">
            {{ listObjectToString(ebooks.authors, 'name') }}
          </p>
          <label class="font-semibold text-sm mb-0">Categories</label>
          <p class="text-base text-gray-500 font-normal">
            {{ listObjectToString(ebooks.categories, 'name') }}
          </p>
          <label class="font-semibold text-sm mb-0">Parental Control</label>
          <p class="text-base text-gray-500 font-normal">
            {{ parentalControl }} ({{ descParentalControl }})
          </p>
          <label v-if="!isProfileVendor" class="font-semibold text-sm mb-0">Category AX</label>
          <p v-if="!isProfileVendor" class="text-base text-gray-500 font-normal">
            {{ ebooks.category_ax ? ebooks.category_ax.category_ax : '-' }}
          </p>
          <label class="font-semibold text-sm mb-0">Current File</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ebooks.file_size | fileSizeFormat }}
          </p>
          <label class="font-semibold text-sm mb-0">Content Type</label>
          <p class="text-base text-gray-500 font-normal uppercase">
            {{ ebooks.content_type }}
          </p>
          <label class="font-semibold text-sm mb-0">Link Preview</label>
          <div v-if="ebooks.item_status == 'ready for consume'">
            <a :href="urlCatalog(ebooks)" target="_blank" rel="noopener noreferrer">{{ urlCatalog(ebooks) }}</a>
          </div>
          <div v-else>
            <span> Link preview not yet showing up, because not ready for consume </span>
          </div>

          <label class="font-semibold text-sm mb-0">Current File</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ebooks.file_size | fileSizeFormat }}
          </p>
          <label class="font-weight-bold">Description</label>
          <div :class="readMoreToggle ? 'max-w-max' : 'h-20 truncate'">{{ ebooks.description }}
          </div>
          <div class="flex justify-end text-right">
            <span class="cursor-pointer" @click="readMore">{{ readMoreToggle ? 'Read More' : 'Less More' }} <i
                :class="readMoreToggle ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'"></i></span>
          </div>
        </div>
        <div class="w-1/2 flex-col">
          <label class="font-semibold text-sm mb-0">Release Date</label>
          <p class="text-base text-gray-500 font-normal">
            {{ datetime24H(ebooks.release_date) }}
          </p>
          <label class="font-semibold text-sm mb-0">Release Schedule</label>
          <p class="text-base text-gray-500 font-normal">
            {{ datetime24H(ebooks.release_schedule) }}
          </p>
          <label class="font-semibold text-sm  mb-1">Status</label>

          <p v-if="ebooks.item_status == itemStatus.Unpublished"
            class="bg-red-100 rounded-lg w-max text-xs text-red-800 p-1 px-2">
            {{ ebooks.item_status }}
          </p>
          <p v-else-if="ebooks.item_status == itemStatus.Published"
            class="bg-blue-100 p-1 px-2  w-max rounded-lg text-xs text-blue-800">
            {{ ebooks.item_status }}
          </p>
          <p v-else-if="ebooks.item_status == itemStatus.Uploaded"
            class="bg-yellow-100  w-max rounded-lg text-xs text-yellow-800 p-1 px-2">
            {{ ebooks.item_status }}
          </p>
          <p v-else-if="ebooks.item_status == itemStatus.UnpublishedMcgrawhill"
            class="bg-gray-100  w-max rounded-lg text-xs text-gray-800 p-1 px-2">
            {{ ebooks.item_status }}
          </p>
          <p v-else class="bg-green-100  w-max p-1 px-2 rounded-lg text-xs text-green-800">
            {{ ebooks.item_status }}
          </p>
          <label class="font-semibold text-sm mb-1">Active</label>
          <p :class="ebooks.is_active ? 'bg-green-100 text-green-800' : 'text-gray-500 bg-gray-100'"
            class="text-sm w-max p-0 px-2 rounded-lg font-normal">
            {{ ebooks.is_active ? 'Yes' : 'No' }}
          </p>

          <label class="font-semibold text-sm mb-1">Internal Content</label>
          <p :class="ebooks.is_internal_content ? 'bg-green-100 text-green-800' : 'text-gray-500 bg-gray-100'"
            class="text-sm w-max p-0 px-2 rounded-lg font-normal">
            {{ ebooks.is_internal_content ? 'Yes' : 'No' }}
          </p>

          <label class="font-semibold text-sm mb-1">Category Retail</label>
          <p :class="ebooks.category_ax && ebooks.category_ax.is_retail ? 'bg-green-100 text-green-800' : 'text-gray-500 bg-gray-100'"
            class="text-sm w-max p-0 px-2 rounded-lg font-normal">
            {{ ebooks.category_ax ? (ebooks.category_ax.is_retail ? 'Yes' : 'No') : '' }}
          </p>


        </div>
      </div>
    </div>
    <div class="flex justify-start bg-white p-4 rounded-lg">
      <div class="block border-0 w-full">
        <h4 class="text-xl font-bold capitalize"> Cover</h4>
        <EditCover />
        <div class="mt-3 mb-2 w-full pr-2 border-t pt-3" v-if="isShowPreview()">
          <h4 class="text-xl font-bold capitalize mb-3"> Preview</h4>
          <div class="grid gap-x-3 gap-y-4 grid-cols-8" id="row-preview" >
            <div v-for="page in ebooks.preview_page" :key="page">
              <img :src="srcPreview(page) + '?' + now" alt="Image" @click="selectedPreview(page)"
                class="w-full img-sm shadow-md rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="w-full mb-3 mt-3 rounded-lg shadow-sm" id="item-ax " v-if="!isProfileVendor">
      <div class="grid gap-4 grid-cols-2 sm ">
      <div class="bg-white rounded-lg px-3 py-4 " id="item-ax" >
        <div class="w-full flex justify-between items-center ">
            <h4 class="text-xl font-bold capitalize mb-0"> Premium AX</h4>
           
            <button class="flex bg-blue-700 p-2 rounded-lg text-white" @click="$refs.modal_premium_ax.show()" >Update Premium AX</button>
        </div>
        <div class="flex-col mt-3">
          <label class="font-semibold text-sm mb-1">Premium Ax</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoaxPremium?.premium_idax ? ItemSoaxPremium?.premium_idax : "Don't have premium ax yet" }}
          </p>
          <label class="font-semibold text-sm mb-1">Base Price</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoaxPremium?.base_price ? ItemSoaxPremium?.base_price : "Don't base price yet" }}
          </p>
          <label class="font-semibold text-sm mb-1">Vendor</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoaxPremium?.vendor ? ItemSoaxPremium?.vendor : "Don't have vendor yet" }}
          </p>  
        </div>
        <label class="font-semibold text-sm mb-1">Vendor Ax</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoaxPremium?.vendor_id_ax ? ItemSoaxPremium?.vendor_id_ax : "Don't have vendor ax yet" }}
          </p>  
      </div>
      <div class="bg-white  rounded-lg px-3 py-4">
        <div class="w-full flex justify-between items-center ">
            <h4 class="text-xl font-bold capitalize mb-0"> Single AX</h4>
            <button class="flex bg-blue-700 p-2 rounded-lg text-white" @click="$refs.modal_single_ax.show()">Update Single AX</button>
        </div>
        <div class="flex-col mt-3">
          <label class="font-semibold text-sm mb-1">Single Ax</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoax ? ItemSoax.single_idax : "Don't have single ax yet" }}
          </p>
          <label class="font-semibold text-sm mb-1">Base Price</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoax?.base_price ? ItemSoax?.base_price : "Don't base price yet" }}
          </p>
          <label class="font-semibold text-sm mb-1">Vendor</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoax?.vendor ? ItemSoax?.vendor : "Don't have vendor yet" }}
          </p>  
        </div>
        <label class="font-semibold text-sm mb-1">Vendor Ax</label>
          <p class="text-base text-gray-500 font-normal">
            {{ ItemSoax?.vendor_id_ax ? ItemSoax?.vendor_id_ax : "Don't have vendor ax yet" }}
          </p> 
      </div>
     
    </div>
    </div>
   
    <b-card v-if="ebooks.item_type == item_types_audio" id="chapter-audio">
      <h4 class="card-title text-center h3 p-1"> Chapter [Audio Only] </h4>
      <b-table :fields="fieldsAudio" hover responsive="sm" :striped="true" :items="audioItem" :show-empty=true>
        <template #cell(is_active)="row">
          <div>
            <span v-if="row.item.is_active" class="badge">&#9989;</span>
            <span v-else class="badge" style="color:red"> &#10005; </span>
          </div>
        </template>
        <template #cell(file_size)="row">
          <div>
            {{ row.item.file_size | fileSizeFormat }}
          </div>
        </template>
      </b-table>
      <b-col v-if="ebooks.item_status == 'ready for upload'">
        <b-button variant="primary" class="font-weight-bold" md="6" lg="2" @click="actionEditChapter">Edit
          Chapter</b-button>
      </b-col>
      <b-col v-else>
        <b-button variant="primary" class="font-weight-bold" md="6" lg="2" @click="actionEditChapter">View
          Chapter</b-button>
      </b-col>
    </b-card>
  
     <div class="mt-3 mb-3 px-4 pb-4 rounded-md bg-white" id="offer-single">
      <h4 class="card-title text-left text-xl px-2 pt-4 font-semibold"> Offers Single</h4>
      <div class="rounded-lg shadow-sm ">
        <t-table :headers="fields" :data="ebooks.single" class="shadow-none border border-gray-50  bg-white">
          <template v-if="!ebooks?.single?.length" v-slot:tbody="props">
            <tbody :class="props.tbodyClass">
              <tr :class="[props.trClass, 'text-center']">
                <td :class="props.tdClass" colspan="8">
                  There are no records to show
                </td>
              </tr>
            </tbody>
          </template>
        <template v-slot:thead="props">
        <thead>
          <tr>
            <th :class="props.thClass" class="text-left">
              Name
            </th> 
  
            <th :class="props.thClass">
              Offer Code
            </th>
            <th :class="props.thClass">
              Price IDR
            </th>
            <th :class="props.thClass">
              Price USD
            </th>
            <th :class="props.thClass">
              Is Free
            </th>
            <th :class="props.thClass">
              Offer Status
            </th>
            <th :class="props.thClass">
              Active
            </th>
            <th :class="props.thClass">
              Created
            </th>
          </tr>
        </thead>
      </template>
      <template slot="row" slot-scope="props">
        <tr
          class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3">
          <td :class="props.tdClass" class="max-w-xs text-left pl-2">
            <router-link :to="{ name: SingleEditPath.name, params: { id: props.row.id } }"
              class="text-left text-blue-700 ">
              {{ props.row.name }}
            </router-link>
          </td>
          <td :class="props.tdClass" class="pl-2">
            {{ props.row.offer_code }}
          </td>
          <td :class="props.tdClass" class="pl-2">
            {{ props.row.price_idr }}
          </td>
          <td :class="props.tdClass" class="pl-2">
            {{ props.row.price_usd }}
          </td>
          <td :class="props.tdClass" class="pl-2">
            <span
              :class="[{ 'bg-green-100 text-green-800': props.row.is_free, 'bg-gray-200 text-gray-800': !props.row.is_free }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']">
              {{ formatBoolean(props.row.is_free) }}
            </span>
          </td>
          <td :class="props.tdClass" class="pl-2">
            <span v-if="props.row.offer_status == '8'" class="bg-red-100 rounded-lg text-xs text-red-800 p-1"> Not For Sale</span>
            <span v-else class="bg-blue-100 p-1 rounded-lg text-xs text-blue-800"> Ready For Sale </span>
          </td>
          <td :class="props.tdClass">
            <span
              :class="[{ 'bg-green-100 text-green-800': props.row.is_active, 'bg-gray-200 text-gray-800': !props.row.is_active }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']">
              {{ formatBoolean(props.row.is_active) }}
            </span>
          </td>
          <td :class="props.tdClass">
            {{ formatterDate(props.row.created) }}
          </td>
          </tr>
          </template >
      </t-table>
      </div>
     </div>

     <div class="mt-2 mb-4 px-4 pb-4 rounded-md bg-white" id="offer-buffets">
      <h4 class="card-title text-left text-xl px-2 pt-4 font-semibold"> Offers Buffets</h4>
      <div class="rounded-lg shadow-sm ">
        <t-table :headers="fields" :data="ebooks.buffets" class="shadow-none border border-gray-50  bg-white">
        <template v-if="!ebooks?.buffets?.length" v-slot:tbody="props">
            <tbody :class="props.tbodyClass">
              <tr :class="[props.trClass, 'text-center']">
                <td :class="props.tdClass" colspan="8">
                  There are no records to show
                </td>
              </tr>
            </tbody>
          </template>
        <template v-slot:thead="props">
        <thead>
          <tr>
            <th :class="props.thClass" class="text-left">
              Name
            </th> 
  
            <th :class="props.thClass">
              Offer Code
            </th>
            <th :class="props.thClass">
              Price IDR
            </th>
            <th :class="props.thClass">
              Price USD
            </th>
            <th :class="props.thClass">
              Is Free
            </th>
            <th :class="props.thClass">
              Offer Status
            </th>
            <th :class="props.thClass">
              Active
            </th>
            <th :class="props.thClass">
              Created
            </th>
          </tr>
        </thead>
      </template>
      <template slot="row" slot-scope="props">
        <tr
          class="border-b dark:bg-gray-800 dark:border-gray-700 bg-54 px-3">
          <td :class="props.tdClass" class="max-w-xs text-left pl-2">
            <router-link :to="{ name: PremiumEditPath.name, params: { id: props.row.id } }"
              class="text-left text-blue-700 ">
              {{ props.row.name }}
            </router-link>
          </td>
          <td :class="props.tdClass" class="pl-2">
            {{ props.row.offer_code }}
          </td>
          <td :class="props.tdClass" class="pl-2">
            {{ props.row.price_idr }}
          </td>
          <td :class="props.tdClass" class="pl-2">
            {{ props.row.price_usd }}
          </td>
          <td :class="props.tdClass" class="pl-2">
            <span
              :class="[{ 'bg-green-100 text-green-800': props.row.is_free, 'bg-gray-200 text-gray-800': !props.row.is_free }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']">
              {{ formatBoolean(props.row.is_free) }}
            </span>
          </td>
          <td :class="props.tdClass" class="pl-2">
            <span v-if="props.row.offer_status == '8'" class="bg-red-100 rounded-lg text-xs text-red-800 p-1"> Not For Sale</span>
            <span v-else class="bg-blue-100 p-1 rounded-lg text-xs text-blue-800"> Ready For Sale </span>
          </td>
          <td :class="props.tdClass">
            <span
              :class="[{ 'bg-green-100 text-green-800': props.row.is_active, 'bg-gray-200 text-gray-800': !props.row.is_active }, 'text-left rounded-lg text-xs p-1 px-2 ml-2']">
              {{ formatBoolean(props.row.is_active) }}
            </span>
          </td>
          <td :class="props.tdClass">
            {{ formatterDate(props.row.created) }}
          </td>
          </tr>
          </template >
      </t-table>
      </div>
     </div>

<t-modal ref="modal_single_ax" >
  <label class="text-base mb-3">Update Single Ax</label>

  <div class="flex-col w-full">
    <div class="mb-2">
      <label for="singleAx" class="block text-sm font-medium text-gray-700">Single Ax</label>
      <t-input
        id="premiumAx"
        v-model="form.singleAx"
        type="number"
        placeholder="Enter Single Ax"
        :min="0"
        required
        class="mt-1 block w-full"
      />
    </div>

    <div class="mb-2">
      <label for="basePrice" class="block text-sm font-medium text-gray-700">Base Price</label>
      <t-input
        id="basePrice"
        v-model="form.basePriceSingle"
        type="number"
        placeholder="Enter Base Price"
        :min="0"
        required
        class="mt-1 block w-full"
      />
    </div>
  </div>

  <div class="flex-col w-full">
    <div class="mb-2">
      <label for="vendor" class="block text-sm font-medium text-gray-700">Vendor</label>
      <multiselect
        v-model="form.vendorSingle"
        label="name"
        track-by="name"
        placeholder="Type Vendor"
        open-direction="bottom"
        :options="vendorSearchOptions"
        :searchable="true"
        :loading="isVendorSearch"
        :close-on-select="true"
        :options-limit="10"
        :multiple="false"
        @search-change="vendorFind"
        @input="getVendorData"
        required
        class="mt-1 block w-full"
      />
    </div>

    <div>
      <label for="vendorAx" class="block text-sm font-medium text-gray-700">Vendor ID AX</label>
      <t-input
        id="vendorAx"
        v-model="form.vendorAxSingle"
        type="text"
        placeholder="Enter Vendor ax"
        required
        class="mt-1 block w-full"
      />
    </div>
  </div>

    <div class="flex justify-end mt-4">
      <button class="bg-blue-700 text-white p-1 px-2 rounded-lg m-1" @click="actionUpdateSingle()">Update</button>
    </div>
</t-modal>


<t-modal ref="modal_premium_ax" >
  <label class="text-base mb-3">Update Premium Ax</label>

  <div class="flex-col w-full">
    <div class="mb-2">
      <label for="premiumAx" class="block text-sm font-medium text-gray-700">Premiuam Ax</label>
      <t-input
        id="premiumAx"
        v-model="form.premiumAx"
        type="number"
        placeholder="Enter Premium Ax"
        :min="0"
        required
        class="mt-1 block w-full"
      />
    </div>

    <div class="mb-2">
      <label for="basePrice" class="block text-sm font-medium text-gray-700">Base Price</label>
      <t-input
        id="basePrice"
        v-model="form.basePricePremium"
        type="number"
        placeholder="Enter Base Price"
        :min="0"
        required
        class="mt-1 block w-full"
      />
    </div>
  </div>

  <div class="flex-col w-full">
    <div class="mb-2">
      <label for="vendor" class="block text-sm font-medium text-gray-700">Vendor</label>
      <multiselect
        v-model="form.vendorPremium"
        label="name"
        track-by="name"
        placeholder="Type Vendor"
        open-direction="bottom"
        :options="vendorSearchOptions"
        :searchable="true"
        :loading="isVendorSearch"
        :close-on-select="true"
        :options-limit="10"
        :multiple="false"
        @search-change="vendorFind"
        @input="getVendorData"
        required
        class="mt-1 block w-full"
      />
    </div>

    <div>
      <label for="vendorAx" class="block text-sm font-medium text-gray-700">Vendor ID AX</label>
      <t-input
        id="vendorAx"
        v-model="form.vendorAxPremium"
        type="text"
        placeholder="Enter Vendor ax"
        required
        class="mt-1 block w-full"
      />
    </div>
  </div>

    <div class="flex justify-end mt-4">
      <button class="bg-blue-700 text-white p-1 px-2 rounded-lg m-1" @click="actionUpdateSingle()">Update</button>
    </div>
</t-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import EditCover from './edit/editCover.vue'
import constant from "../../store/constant";
import {
  BookEditPath,
  BookPath,
  MagazinePath,
  NewspaperPath,
  AudiobookPath,
  AudioEditPath,
  MagazineEditPath,
  NewspaperEditPath,
  EditCoverItemPath,
  EditPreviewItemPath,
} from "../../router/content";
import { PremiumEditPath, SingleEditPath, SubscriptionEditPath } from "../../router/marketing";
export default {
  name: "ebookDetail",
  components: {
    EditCover
  },
  data() {
    return {
      form: {
        premiumAx: 0,
        basePricePremium: 0,
        vendorPremium: '',
        vendorAxPremium: '',
        singleAx: 0,
        basePriceSingle: 0,
        vendorSingle: '',
        vendorAxSingle: '',
      },
      item_types_audio: 'audio book',
      vendorSearchOptions: [],
      isVendorSearch: false,
      offers: [],
      dataSingle: [],
      now: +new Date(),
      activePreview: null,
      EditCoverItemPath,
      EditPreviewItemPath,
      SingleEditPath,
      readMoreToggle: false,
      PremiumEditPath,
      itemStatus: constant.ITEM_STATUS,
      SubscriptionEditPath,
      basePathCover: constant.URL_STATIC.EBOOK_COVER,
      basePathPreview: constant.URL_STATIC.EBOOK_PREVIEW,
      listPath: {
        'book': BookPath.name,
        'magazine': MagazinePath.name,
        'newspaper': NewspaperPath.name,
        'audio book': AudiobookPath.name,
      },
      fieldsAudio: [
        { key: 'chapter', label: 'Chapter' },
        { key: 'title', label: 'Title' },
        { key: 'file_time', label: 'Duration' },
        { key: 'file_name', label: 'File Name' },
        { key: 'file_size', label: 'File Size' },
        { key: 'is_active', label: 'Is Active' },
        {
          key: 'created', label: 'Created', formatter: function (value) {
            return moment(value).format('DD-MM-YYYY');
          },
        },
      ],
      
      fields: [
        {
          key: 'name',
          label: 'Name',
          thStyle: { width: "20%" },
          tdStyle: { width: "20%" }
        },
        { key: 'offer_code', label: 'Offer Code' },
        // { key:'item_code', label: 'Item Code'},
        {
          key: 'price_idr',
          label: 'Price IDR',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'price_usd',
          label: 'Price USD',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key: 'is_free',
          label: 'Is Free',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'offer_status',
          label: 'Offer Status', formatter: function (value) {
            if (value == '7') {
              return "Ready For Sale"
            } else if (value == '8') {
              return "Not For Sale"
            } else {
              return value
            }

          }
        },
        {
          key: 'is_active',
          sortable: true,
          label: 'Active'
        },
        {
          key: 'created',
          label: 'Created',
          formatter: function (value) {
            return moment(value).format('DD MMM YYYY')
          }
        }
      ],
      item_id: null,
      parentalControl: '',
      descParentalControl: '',
    };
  },
  mounted() {
    let id = this.$route.params.id
    this.actionGetEbooks(id);
    this.actionGetSoAx(id);
    this.item_id = id;
  },
  watch: {
    ebooks: function (data) {
      if (!data.id) {
        return
      } else {
        this.activePreview = this.basePathCover + this.removeBucket(data.image_highres);
        // this.dataSingle = data.single
        this.actionGetParentalControl(data.parentalcontrol_id)
        this.getVendorId(parseInt(data.brands?.vendor?.id)).then(response => {
          this.form.vendorSingle = response.data?.data?.rows[0];
          this.form.vendorPremium = response.data?.data?.rows[0];
          this.form.vendorAxSingle = response.data?.data?.rows[0]?.sync_vendor?.vendor_id_ax;
          this.form.vendorAxPremium = response.data?.data?.rows[0]?.sync_vendor?.vendor_id_ax;
        })
        return
      }

    }


  },
  computed: {
    ...mapState({
      vendors: (state) => state.vendors.items,
      ebooks: (state) => state.ebooks.item,
      ItemSoax: (state) => state.ItemSoax.item,
      ItemSoaxPremium: (state) => state.ItemSoax.itemPremium,
      isLoading: (state) => state.ebooks.isLoading,
      isError: (state) => state.ItemSoax.isError,
      audioItem: (state) => state.ebooks.itemAudio,
      isProfileVendor: (state) => state.profile?.vendor_id,
    }),
  },
  created() {
    this.fetchProfile();
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions("vendors", ["searchVendor", "getVendorId"]),
    ...mapActions("ebooks", ["fetchEbookById", 'getParentalControl']),
    ...mapActions("ItemSoax", ["fetch", "search", "fetchSoAxByItemsId", "updateSoAxByItemsId", "createSoAxByItemsId", 'fetchSoAxByItemsIdPremium', 'updateSoAxPremiumByItemsId']),
    actionGetEbooks(id) {
      let payload = {
        id: id
      };
      this.fetchEbookById(payload)
    },

    actionGetParentalControl(id) {
      this.getParentalControl(id)
        .then((res) => {
          this.parentalControl = res.data.data.name
          this.descParentalControl = res.data.data.description
        })
        .catch((err) => {
          if (err.response.status == 422) {
            err.response.data.data.errors.map((e) => {
              this.messageAlert('error', e.msg)
            })
          } else {
            this.messageAlert('error', err.response.data.message)
          }
        })
    },
    readMore() {
      console.log('ada')
      this.readMoreToggle = !this.readMoreToggle
    },
    formatBoolean(value) {
      return value ? 'Yes' : 'No';
    },
    formatterDate: (value) => {
      return moment(value).format('DD MMM YYYY');
    },
    actionGetSoAx(id) {
      let payload = {
        id: id
      };

      this.fetchSoAxByItemsId(payload).then((data) => {
        this.form.singleAx = data?.single_idax ?? 0;
        this.form.basePriceSingle = data?.base_price ?? 0;
      });
      this.fetchSoAxByItemsIdPremium(payload)
        .then((data) => {
          this.form.premiumAx = data?.premium_idax ?? 0;
          this.form.basePricePremium = data?.base_price ?? 0;
        })
    },
    vendorFind(query) {
      if (!query) return;
      this.isVendorSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let payload = {
          q: query,
          limit: 10,
        };
        this.searchVendor(payload).then((response) => {
          this.vendorSearchOptions = response.data.data.rows;
          this.isVendorSearch = false;
        }).catch(() => {
          this.isVendorSearch = false;
        });
      }, 600);
    },
    getVendorData() {
      this.form.vendorAx = this.form.vendor?.sync_vendor?.vendor_id_ax

    },
    removeBucket(str) {
      if (str && str.substring(0, 6) == "images") {
        return str.substring(9);
      } else {
        return str ?? 'default.png';
      }
    },
    listObjectToString(data = [], attr) {
      return data.map(item => item[attr]).join(', ');
    },
    itemTypes(type) {
      if (type == 'magazine') {
        return 'majalah/';
      }
      if (type == 'newspaper') {
        return 'koran/';
      }
    },
    urlCatalog() {
      const baseURLWeb = 'https://ebooks.gramedia.com/id/';
      const itemSlug = this.ebooks?.slug;
      const brandSlug = this.ebooks?.brands?.slug;

      if (this.ebooks.item_type == constant.ITEM_TYPES.BOOK.toLocaleLowerCase()) {
        return baseURLWeb + 'buku/' + itemSlug;
      }
      if (this.ebooks.item_type == constant.ITEM_TYPES.MAGAZINE.toLocaleLowerCase()) {
        const slugEdition = `${itemSlug}`.replace(brandSlug + '-', '');
        return baseURLWeb + 'majalah/' + brandSlug + '/' + slugEdition;
      }
      if (this.ebooks.item_type == constant.ITEM_TYPES.NEWSPAPER.toLocaleLowerCase()) {
        const slugEdition = `${itemSlug}`.replace(brandSlug + '-', '');
        return baseURLWeb + 'koran/' + brandSlug + '/' + slugEdition;
      }

      return baseURLWeb;
    },
    goToEdit() {
      if (this.ebooks.item_type == constant.ITEM_TYPES.BOOK.toLocaleLowerCase()) {
        this.$router.push({ path: BookEditPath.path.replace(':id', this.ebooks.id) });
      }
      if (this.ebooks.item_type == constant.ITEM_TYPES.MAGAZINE.toLocaleLowerCase()) {
        this.$router.push({ path: MagazineEditPath.path.replace(':id', this.ebooks.id) });
      }
      if (this.ebooks.item_type == constant.ITEM_TYPES.NEWSPAPER.toLocaleLowerCase()) {
        this.$router.push({ path: NewspaperEditPath.path.replace(':id', this.ebooks.id) });
      }
      if (this.ebooks.item_type == constant.ITEM_TYPES.AUDIOBOOK.toLocaleLowerCase()) {
        this.$router.push({ path: AudioEditPath.path.replace(':id', this.ebooks.id) });
      }
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    actionUpdateSingle() {
      this.$swal({
        title: "Are you sure?",
        text: "You want Update ?!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update!",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = this.form
          this.updateSoAxByItemsId({
            id: this.item_id,
            name: this.ebooks.name,
            single_idax: data.singleAx,
            vendor: data.vendorSingle?.name ?? '',
            vendor_id_ax: data.vendorAxSingle,
            base_price: data.basePriceSingle,
          }).then(async (response) => {
            if (response.status === 200) {
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'Single ID AX Has been Updated',
                timer: 5000,
                text: this.message,
              });
              let id = this.$route.params.id
              this.actionGetSoAx(id);
              this.$bvModal.hide('modal-1');
            } else {
              this.$swal({
                icon: 'error',
                toast: true,
                position: 'top-end',
                title: "Something's Error",
                timer: 3000,
                text: response,
              });
              this.$bvModal.hide('modal-1');
            }

          })
            .catch((error) => {
              console.log("error", error.message);
            });
        }

      });
    },
    actionUpdatePremium() {
      this.$swal({
        title: "Are you sure?",
        text: "You want Update ?!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update!",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = this.form
          this.updateSoAxPremiumByItemsId({
            id: this.item_id,
            name: this.ebooks.name,
            premium_idax: data.premiumAx,
            vendor: data.vendorPremium?.name ?? '',
            vendor_id_ax: data.vendorAxPremium,
            base_price: data.basePricePremium,
          }).then(async (response) => {
            if (response.status === 200) {
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'Premium ID AX Has been Updated',
                timer: 5000,
                text: this.message,
              });
              let id = this.$route.params.id
              this.actionGetSoAx(id);
              this.$bvModal.hide('modal-2');
            } else {
              this.$swal({
                icon: 'error',
                toast: true,
                position: 'top-end',
                title: "Something's Error",
                timer: 3000,
                text: response,
              });
              this.$bvModal.hide('modal-2');
            }

          })
            .catch((error) => {
              console.log("error", error.message);
            });
        }

      });
    },
    actionCreate() {
      this.$swal({
        title: "Are you sure?",
        text: "You want Create ?!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Create!",
      }).then((result) => {
        if (result.isConfirmed) {
          const data = this.form
          this.createSoAxByItemsId({
            id: this.item_id,
            name: this.ebooks.name,
            premium_idax: data.premiumAx,
            single_idax: data.singleAx,
            vendor: data.vendor.id ?? '',
            vendor_id_ax: data.vendorAx,
            base_price: data.basePrice,
          }).then(async (response) => {
            if (response.status === 200) {
              this.$swal({
                icon: 'success',
                toast: true,
                position: 'top-end',
                title: 'Detail Ax Created',
                timer: 5000,
                text: this.message,
              });

              let id = this.$route.params.id
              this.actionGetSoAx(id);
              this.$bvModal.hide('modal-1');
            } else {
              this.$swal({
                icon: 'error',
                toast: true,
                position: 'top-end',
                title: "Something's Error",
                timer: 3000,
                text: response,
              });
              this.$bvModal.hide('modal-1');
            }
          })
            .catch((error) => {
              console.log("error", error.message);
            });
        }

      });
    },
    actionEditChapter() {
      this.$router.push({
        path: `/audio/editChapter/${this.item_id}`,
      });
    },
    srcPreview(page) {
      return this.basePathPreview +
        this.ebooks.brand_id + '/' +
        this.ebooks.id + '/' +
        page + '.jpg';
    },
    selectedPreview(page) {
      this.activePreview = this.srcPreview(page);
    },
    isShowPreview() {
      const typeBook = constant.ITEM_TYPES.BOOK.toLowerCase();
      const typeMagazine = constant.ITEM_TYPES.MAGAZINE.toLowerCase();
      if (!this.ebooks?.page_count) return false;
      if (this.ebooks.item_type == typeBook) {
        return true;
      } if (this.ebooks.item_type == typeMagazine) {
        return true;
      }
      return false;
    },
  },
}
</script>

<style lang="css" scoped>
.img-sm {
  cursor: pointer;
  transition: transform .2s;
}

.img-sm:hover {
  box-shadow: 0 0 15px 0 grey !important;
  transform: scale(3);
}

#cover-preview {
  z-index: 1;
}
</style>
